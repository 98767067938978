@import "../../../variables.module.scss";

.headerTitle {

  .sticky {

    .aboutUs {
      text-align: center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
    }
  }

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 5px;
    align-items: center;

  }

  .bgShape {
    width: 100%;
    display: none;
  }
}


.planSelectPage {

  .marginTop {
    padding-top: 60px;
  }

  .planselect {
    svg {
      text-align: right;
      float: right;
      background-color: #3874c4;
      border-radius: 100px;
      padding: 6px;
      cursor: pointer;
      font-size: 18px;
      color: #fff;
      width: 30px;
      height: 30px;
      position: sticky;
      top: 90px;
    }

    .planselectBox {
      margin-top: 20px;
      padding: 10px;
      border-radius: 10px;
      border: dashed;
      background: #eaf3ff3d;
      border-color: #3974c4;
      border-width: 2px;

      .dropDOwnSelect {

        .mobileSHow {
          display: none !important;
        }

        label {
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 6px;
          color: #375dbc;
        }

        :global(.form-select) {
          border-radius: 10px;
          padding: 12px 18px;
          border: 1px solid #e1e1e1;

          &:focus {
            box-shadow: none;
            border: 1px solid #375dbc;
          }
        }
      }

      .textAreaAdmin {
        margin-top: 18px;
        position: relative;

        .areaContentTop {
          position: absolute;
          top: 0;
          left: 0;
          padding: 10px;
        }

        :global(.form-select) {
          border-radius: 10px;
          padding: 12px 18px;
          border: 1px solid #e1e1e1;
          background: #fff;
          height: 150px;

          &:focus {
            box-shadow: none;
            border: 1px solid #375dbc;
            box-shadow: none !important;
            outline: none !important;
          }
        }

      }

      .planList {
        margin-top: 18px;

        ul {
          list-style: none;
          padding-left: 0px;
          margin-bottom: 0px;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 14px;

            span {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
            }

            :global(.form-control) {
              border-radius: 10px;
              padding: 8px 18px;
              max-width: 120px;
              border: 1px solid #e1e1e1;
              text-align: center;
            }
          }
        }
      }
    }

  }
}




/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .planSelectPage {

    .marginTop {
      padding-top: 20px;
    }

    .planselect {
      svg {

        top: 10px;
      }

      .webShow {
        // display: none;
      }

      .planselectBox {

        .dropDOwnSelect {

          .mobileSHow {
            display: block !important;
          }
        }
      }

    }
  }

  .mobilePadding {
    padding: 0px 16px !important;
  }

  .bgShape {
    display: block !important;
  }
}