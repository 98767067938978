@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .back {
      color: #000 !important;
      font-size: 20px;
    }

    .headTitle {
      text-align: center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}

.sliderHome {
  padding: 10px 0px 0px 0px;


}

.detailsBox {
  padding: 10px 20px;

  img {
    width: 100%;
    height: 420px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 15px;
  }
}

.bookBtn {
  background: var(--Main) !important;
  border: none !important;
  display: block;
  width: 460px;
  text-align: center;
  color: #fff !important;
  height: 50px;
  border-radius: 8px;
  line-height: 50px;
  padding: 0px;
  margin: 0 auto;
}

.contentBody {
  padding: 20px 20px;
}


.popupBox {
  .bgBox {
    background: #00000070;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }


  &.popupShow {

    .popupBoxBody {
      bottom: 0;
      display: block;
    }

    .bgBox {
      bottom: 0px;
    }
  }

  .popupBoxBody {
    position: fixed;
    z-index: 999;
    width: 100%;
    bottom: -100%;
    background: #fff;
    overflow: hidden;
    max-height: calc(100vh - 150px);
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 20px 20px 0px 0px;
    transition: 0.5s;
    display: none;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }

    .closeIcon {
      position: absolute;
      right: 0px;
      z-index: 9999;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      box-shadow: 0 0px 4px 1px #ebebeb;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
    }

    .popupTitle {
      position: fixed;
      left: 0;
      border-bottom: 1px solid #dfdede;
      padding: 20px 15px 20px 15px;
      background: #fff;
      width: 100%;
      border-radius: 20px 20px 0px 0px;
      z-index: 9999;

      h5 {
        text-align: center;
        font-size: 20px;
        margin: 0px;
        position: relative;
      }
    }


    .cotentBody {
      padding: 100px 20px 20px 20px;

      h6 {
        font-size: 20px;
        margin-bottom: 12px;
      }

      .profileEdit {
        margin-bottom: 16px;

        label {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 6px;
        }

        input {
          width: 100%;
          border-radius: 10px;
          padding: 12px 18px;
          border: 1px solid #e1e1e1;
        }

        input:focus {
          outline: none !important;
        }

        select {
          border-radius: 10px;
          padding: 12px 18px;
          border: 1px solid #e1e1e1;
        }

        select:focus {
          outline: none !important;
        }

        textarea {
          width: 100%;
          border-radius: 10px;
          padding: 12px 18px;
          border: 1px solid #e1e1e1;
        }

        textarea:focus {
          outline: none !important;
        }

        :global(.react-tel-input) {
          input {
            padding-left: 48px !important;
            height: auto !important;
          }
        }

        :global(.css-b62m3t-container) {
          border-radius: 10px;
          padding: 5px 6px;
          border: 1px solid #e1e1e1;
          border-style: solid !important;
        }

        :global(.css-13cymwt-control) {
          border: none !important;

          span {
            display: none !important;
          }
        }

        :global(.css-1s2u09g-control) {
          border: none !important;

          span {
            display: none !important;
          }
        }

        .compairPlans {
          text-align: right;
          margin-top: 14px;

          h5 {
            font-size: 18px;
            font-weight: 500;
          }
        }

      }
    }
  }
}


.selectBoxButton {
  margin-top: 10px;
  display: flex;
  justify-content: end;

  .selectPlanButton {
    font-weight: 600;
    background: transparent;
    color: var(--Main);
    border: none;
  }

}



.details_classPopup {
  position: relative;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 11111;
  background: #fff;

  button {
    width: 20px;
    height: 20px;
    background-color: #375DBC !important;
    opacity: 1;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1em auto;
    position: static;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

  .popupBox {

    .bgBox {
      width: 100%;
    }

    .popupBoxBody {
      width: 100%;

      .popupTitle {
        width: 100%;
      }

    }
  }

  .bookBtn {
    width: 100%;
  }

  .detailsBox {
    padding: 10px 20px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 15px;
    }
  }
}